import Button from '../Button';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled from 'styled-components';

export const Render = styled.div`
  margin-bottom: 96px;

  &:last-child {
    margin-bottom: 0;
  }

  .btn-flex {
    margin: 0 -16px;
    align-items: stretch;
    margin-bottom: 160px;

    & > li {
      width: 25%;
      padding: 0 16px;

      .btn-report {
        line-height: 1.6;
        height: 100%;
        padding: 8px 24px;
      }
    }
  }

  ${breakpoint(1000)} {
    .btn-flex {
      flex-wrap: wrap;
      & > li {
        width: 50%;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .btn-report {
          height: 82px;
        }
      }
    }
  }

  ${breakpoint(`mobile`)} {
    .btn-flex {
      & > li {
        width: 100%;
        ${Button} {
          height: 56px;
          line-height: 54px;
          padding: 0 24px;
          br {
            display: none;
          }
        }
      }
    }
  }
`;

export const Article = styled.article`
  margin-bottom: 96px;

  ${breakpoint(`tablet`)} {
    margin-bottom: 72px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3.sky-bd {
    line-height: 1.5;
    padding-bottom: 8px;
    border-bottom: 1px solid #1cabe2;
    margin-bottom: 30px;
  }

  .type-2 {
    padding: 32px 0 64px 0;
    border-bottom: 1px solid #e5e6e8;
    margin-bottom: 32px;

    ${breakpoint(`tablet`)} {
      padding-bottom: 28px;
    }
  }
  .process-ol {
    .list-item {
      width: 33.33% !important;
      margin-bottom: 0;

      dl {
        align-items: center;

        h3 {
          margin-bottom: 0;
        }
        ${Tit} {
          font-size: 20px;

          ${breakpoint(1050)} {
            font-size: 1.9vw;
          }
          ${breakpoint(`tablet`)} {
            font-size: 16px;
          }
        }

        .dec-ul {
          margin-top: 0;
          margin-top: 5px;
          line-height: 1.2;
          color: #828385;
          strong {
            color: #2d2926;
          }
        }
      }
    }

    ${breakpoint(`tablet`)} {
      flex-wrap: wrap;
      .list-item {
        width: 100% !important;
        margin-bottom: 34px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .process-ol {
      & > li {
        ${Tit} {
          font-size: 16px !important;
        }
      }
    }
  }
`;
export const ChartContainer = styled.div`
  padding: 64px 0;
  ${breakpoint(`tablet`)} {
    padding: 0;
  }
`;

export const Summary = styled.p`
  text-align: right;
  color: #828385;
  font-weight: 700;
`;

export const TableContainer = styled.div`
  ${Summary} {
    // margin-top: 30px;
    margin-bottom: 8px;
  }
  table {
    margin-top: 0;

    .col1,
    .col2 {
      width: 50%;
    }
    .col3,
    .col4 {
      width: 25%;
    }

    th,
    td {
      height: 60px;
      padding: 5px 7px;
    }
    tbody {
      tr:last-child {
        td {
          border-bottom-color: #ddd;
        }
      }

      &:last-child {
        tr:last-child {
          td,
          th {
            border-bottom-color: #2d2926;
          }
        }
      }
      td {
        text-align: left;
        .country {
          padding-left: 20px;
        }
      }

      th {
        .dec,
        .price {
          font-weight: 400;
        }
      }
      &.developed-tbody {
        .continents {
          padding: 0 7px;
        }
        .country {
          padding: 0 20px;
        }
      }
    }

    &.tbl1 {
      .col1,
      .col2 {
        width: 41%;
      }
      .col3 {
        width: 18%;
      }
    }

    &.tbl2 {
      .col1,
      .col2 {
        width: 34%;
      }
      .col3 {
        width: 18%;
      }
      .col4 {
        width: 14%;
      }
    }
  }
`;

export const NoticeBox = styled.div`
  margin-top: 16px;
  background: #f8f9fa;
  padding: 16px 24px;
  strong {
    font-weight: 700;
  }

  ${breakpoint(`tablet`)} {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export const MapContainer = styled.dl`
  padding-top: 32px;

  dt {
    margin-top: 32px;
  }
`;
