import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { VFC } from 'react';
import styled from 'styled-components';

const Highcarts = styled(HighchartsReact)`
  .highcharts-title {
    font-size: 10px !important;
  }

  span {
    font-size: 5px !important;
  }
`;

const options: Options = {
  colors: [
    `#1cabe2`,
    `#72C9EB`,
    `#80bd41`,
    `#ffc20e`,
    `#f26a21`,
    `#e2231a`,
    `#961a49`,
    `#961a49`,
  ],
  chart: {
    plotShadow: false,
    type: `pie`,
    height: `500px`,
  },
  tooltip: {
    pointFormat: `{series.name} <br>{point.y:.1f}%</br>`,
  },
  accessibility: {
    point: {
      valueSuffix: `%`,
    },
  },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: `pointer`,
      dataLabels: {
        padding: 0,
        enabled: true,
        format: `{point.name} {point.y:.1f} %`,
        style: {
          fontSize: `16px`,
          fontWeight: `normal`,
          fontFamily: `'Noto Sans KR',sans-serif`,
        },
      },
    },
  },

  credits: {
    enabled: false,
  },
};

const DonutChart: VFC<
  Pick<Options, 'series'> & { centerText: string; fontSize?: string }
> = ({ series, centerText, fontSize }) => (
  <Highcarts
    highcharts={Highcharts}
    options={{
      ...options,
      series,
      title: {
        text: centerText,
        align: `center`,
        verticalAlign: `middle`,
        y: 30,
        style: {
          fontSize: `16px`,
          fontFamily: `'Noto Sans KR',sans-serif`,
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 800,
            },
            chartOptions: {
              chart: {
                height: `80%`,
              },
              title: {
                y: 15,
              },
              plotOptions: {
                pie: {
                  center: [`50%`, `50%`],
                  size: `80%`,
                  dataLabels: {
                    style: { fontSize: fontSize || `8px` },
                  },
                },
              },
            },
          },
        ],
      },
    }}
  />
);

export default DonutChart;
