import TransparencyBn from '@/assets/img/about-us/transparency_bn@2x.png';
import Report2019 from '@/assets/img/about-us/transparency_report2019@2x.jpg';
import Report2020 from '@/assets/img/about-us/transparency_report2020@2x.png';
import Report2021 from '@/assets/img/about-us/transparency_report2021@2x.png';
import Report2022 from '@/assets/img/about-us/transparency_report2022@2x.jpg';
import LogoGuideStar from '@/assets/img/logo_guide_star_crown.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import {
  Article,
  TableContainer,
  NoticeBox,
} from '@/components/Css/ReportKorea';
import DonutChartComponent from '@/components/DonutChart';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import { SectionTop } from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps, navigate } from 'gatsby';
import { Options } from 'highcharts';
import React, { useEffect } from 'react';
import styled from 'styled-components';

export const BtnFlex = styled.ul`
  margin: 0 -16px;
  align-items: stretch;
  display: flex;

  & > li {
    width: 25%;
    padding: 0 16px;

    .btn-report {
      margin-top: 16px;
      padding: 12px 24px;
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;

    & > li {
      width: 50%;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    flex-wrap: wrap;

    & > li {
      width: 100%;
    }
  }
`;

const ChartArticle = styled(Article)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px !important;

  ${breakpoint(`tablet`)} {
    display: block;
    margin-bottom: 13px !important;
  }
`;

const ChartContainer = styled.div`
  margin-top: 32px;
  width: 45%;

  ${breakpoint(`tablet`)} {
    width: 100%;
  }
`;

const ChartTable = styled(TableContainer)`
  table tbody td {
    text-align: center !important;
  }
`;

const ArcticleContainer = styled.div`
  .dec {
    word-break: break-all;
  }

  article {
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    h3 {
      padding-bottom: 32px;
    }

    small {
      font-size: 14px;
      color: #828385;
      margin-top: 8px;
      display: block;
      word-break: break-all;
    }

    ${BtnFlex} {
      margin-top: 32px;

      & > li {
        width: 33.33%;

        .btn-report {
          margin-top: 0;
          line-height: 1.6;
          height: 100%;
          padding: 8px 24px;
        }
      }
    }

    ${breakpoint(`tablet`)} {
      ${BtnFlex} {
        flex-wrap: wrap;

        & > li {
          width: 100%;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          .btn-report {
            height: 82px;
          }
        }
      }
    }
  }
`;
const FactBn = styled(LinkSafe)`
  display: block;
  background: #1cabe2;
  margin-top: 74px;
  position: relative;
  padding: 56px 40px 60px;

  &::before {
    content: '';
    display: block;
    width: 192px;
    height: 192px;
    border-radius: 0 0 192px 0;
    background: #2589c9;
    position: absolute;
    left: 0;
    top: 0;
  }

  &:hover {
  }

  ${Tit} {
    display: block;
    position: relative;
    z-index: 1;
  }

  .c-yellow {
    color: #ffdc0e;
  }

  ${Image} {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 418px;
  }
`;

const ArticleWithImageWrapper = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint(`mobile`)} {
    flex-direction: column;
    align-items: start;
  }

  .image {
    min-width: 200px;
    margin-right: 30px;

    ${breakpoint(`mobile`)} {
      width: 200px;
      margin-bottom: 20px;
    }
  }
`;

const customOptions: Options = {
  plotOptions: {
    pie: {
      dataLabels: {
        enabled: true,
        format: `{point.name} {point.percentage}%`,
        style: {
          fontSize: `16px`,
          fontWeight: `normal`,
          fontFamily: `'Noto Sans KR',sans-serif`,
        },
      },
    },
  },
};

const incomeData = [
  {
    type: `후원금 수입`,
    data: [
      {
        name: `정기 후원`,
        amount: 127562758629,
        rate: 88.8,
      },
      {
        name: `일시 후원`,
        amount: 8292110107,
        rate: 5.8,
      },
      {
        name: `기업 후원`,
        amount: 5838080099,
        rate: 4.1,
      },
      {
        name: `비영리기관 후원`,
        amount: 1869207384,
        rate: 1.3,
      },
    ],
  },
];

const incomeTotalArry = [0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomeTotalArry[index] += row2.amount;
  });
});
const incomeTotal = incomeTotalArry[0];

const incomePerArry = [0, 0];
incomeData.map((row, index) => {
  row.data.map((row2) => {
    incomePerArry[index] += (row2.amount / incomeTotal) * 100;
  });
});

const spendingData = [
  {
    type: `후원금 지출`,
    data: [
      {
        name: `개발도상국 어린이 지원비`,
        amount: 124176452862,
        rate: 86.5,
      },
      {
        name: `아동권리 옹호 및 PR 등`,
        amount: 13157326337,
        rate: 9.2,
      },
      {
        name: `인건비`,
        amount: 4300331366,
        rate: 3.0,
      },
      {
        name: `관리 운영비`,
        amount: 1928045654,
        rate: 1.3,
      },
    ],
  },
];
const spendingTotalArry = [0];
spendingData.map((row, index) => {
  row.data.map((row2) => {
    spendingTotalArry[index] += row2.amount;
  });
});

const spendingTotal = spendingTotalArry[0];

const numberFormat = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);

const Transparency: React.FC<PageProps> = ({ location }) => {
  // '재정투명성' 페이지 사용 종료로 '기금 사용처' 페이지로 바로 이동
  useEffect(() => {
    navigate('/about-us/clear/fund/unicef-kr');
  }, []);
  return null;

  return (
    <LayoutWithTitle
      location={location}
      title="재정 투명성"
      description="for every child, transparency"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  유니세프한국위원회는 <br />
                  후원금을 투명하게 운영합니다
                </PageComponent>
              </Tit>
            </h2>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="WK40EOcSboM" />
        </PageComponent>
      </SectionTop>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">투명경영</Tit>
            </h2>
          </SectionHeader>

          <ArcticleContainer>
            <article>
              <h3>
                <Tit size="s2">정기감사</Tit>
              </h3>
              <p className="dec">
                유니세프한국위원회는 내부 감사, 유니세프 본부 및 외부회계법인
                감사를 통해 후원금 사용의 투명성을 검증받습니다.
              </p>
              <NoticeBox>
                <ul className="blit-list">
                  <li>
                    <strong>유니세프한국위원회 내부 감사</strong> 연 1회 이사회
                    감사
                  </li>
                  <li>
                    <strong>유니세프 본부 감사</strong> 연 1회 감사
                  </li>
                  <li>
                    <strong>외부 감사</strong> 연 1회 (2022년 삼덕회계법인 감사)
                  </li>
                </ul>
              </NoticeBox>
            </article>

            <article>
              <h3>
                <Tit size="s2">국세청 공시</Tit>
              </h3>
              <p className="dec">
                공익법인 결산서류 공시 의무에 따라 매년 국세청에 결산 서류, 연간
                기부금 모금액 및 활용실적 등을 제출하여 국세청 웹사이트에
                공시합니다.
              </p>
            </article>

            <article>
              <h3>
                <Tit size="s2">2022 유니세프한국위원회 재정 보고</Tit>
              </h3>

              <p className="dec">
                2022년 유니세프한국위원회는 약 1,436억 원을 모금해 약 1,242억
                원을 유니세프 본부에 개발도상국 어린이 지원금으로 송금했습니다.
                여러분이 100원을 기부하시면 이 중 86.5원이 송금된 셈입니다.
                나머지 13.5원 중 3원은 인건비로 사용되고, 1.5원은 관리 운영비,
                9원은 아동권리 옹호 및 PR 등으로 사용되었습니다. 이러한 높은
                송금률은 도움 주는 33개 유니세프 국가위원회 중 가장 높은
                수준이며, 국내 구호단체들 중에서도 가장 높은 그룹에 속합니다.
              </p>
              <ChartArticle>
                <ChartContainer>
                  <div className="m-hide">
                    <DonutChartComponent
                      centerText={`<span style="font-size: 18px;">후원금 수입</span><br><b style="font-size: 26px;letter-spacing: -1.6px;color:#3162af">${numberFormat(
                        incomeTotal,
                      )}</b><b style="font-size:18px;color:#3162af">원</b>`}
                      fontSize="12px"
                      series={[
                        {
                          name: ``,
                          colorByPoint: true,
                          innerSize: `65%`,
                          type: `pie`,
                          data: [
                            {
                              name: incomeData[0].data[0].name,
                              y: incomeData[0].data[0].rate,
                            },
                            {
                              name: incomeData[0].data[1].name,
                              y: incomeData[0].data[1].rate,
                            },
                            {
                              name: incomeData[0].data[2].name,
                              y: incomeData[0].data[2].rate,
                            },
                            {
                              name: incomeData[0].data[3].name,
                              y: incomeData[0].data[3].rate,
                            },
                          ],
                        },
                      ]}
                      customOptions={customOptions}
                    />
                  </div>
                  <div className="pc-hide">
                    <DonutChartComponent
                      centerText={`<span style="font-size: 12px;">후원금 수입</span><br><b style="font-size: 18px;letter-spacing: -1.6px;color:#3162af">${numberFormat(
                        incomeTotal,
                      )}</b><b style="font-size:12px;color:#3162af">원</b>`}
                      series={[
                        {
                          name: ``,
                          colorByPoint: true,
                          innerSize: `65%`,
                          type: `pie`,
                          data: [
                            {
                              name: incomeData[0].data[0].name,
                              y: incomeData[0].data[0].rate,
                            },
                            {
                              name: incomeData[0].data[1].name,
                              y: incomeData[0].data[1].rate,
                            },
                            {
                              name: incomeData[0].data[2].name,
                              y: incomeData[0].data[2].rate,
                            },
                            {
                              name: incomeData[0].data[3].name,
                              y: incomeData[0].data[3].rate,
                            },
                          ],
                        },
                      ]}
                      customOptions={customOptions}
                    />
                  </div>
                  <ChartTable>
                    <table className="tbl1">
                      <colgroup>
                        <col className="col1" />
                        <col className="col2" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>금액(원)</th>
                        </tr>
                      </thead>
                      {incomeData.map((row, index) => (
                        <tbody key={index}>
                          {row.data.map((row2) => (
                            <tr>
                              <td>{row2.name}</td>
                              <td>
                                <p className="price">
                                  {numberFormat(row2.amount)}
                                </p>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <strong>계</strong>
                            </td>
                            <td>
                              <p className="price">
                                {numberFormat(incomeTotalArry[index])}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </ChartTable>
                </ChartContainer>
                <ChartContainer>
                  <div className="m-hide">
                    <DonutChartComponent
                      centerText={`<span style="font-size: 18px;">후원금 지출</span><br><b style="font-size: 26px;letter-spacing: -1.6px;color:#3162af">${numberFormat(
                        spendingTotal,
                      )}</b><b style="font-size:18px;color:#3162af">원</b>`}
                      fontSize="12px"
                      series={[
                        {
                          name: ``,
                          colorByPoint: true,
                          innerSize: `65%`,
                          type: `pie`,
                          data: [
                            {
                              name: spendingData[0].data[0].name,
                              y: spendingData[0].data[0].rate,
                            },
                            {
                              name: spendingData[0].data[1].name,
                              y: spendingData[0].data[1].rate,
                            },
                            {
                              name: spendingData[0].data[2].name,
                              y: spendingData[0].data[2].rate,
                            },
                            {
                              name: spendingData[0].data[3].name,
                              y: spendingData[0].data[3].rate,
                            },
                          ],
                        },
                      ]}
                      customOptions={customOptions}
                    />
                  </div>
                  <div className="pc-hide">
                    <DonutChartComponent
                      centerText={`<span style="font-size: 12px;">후원금 지출</span><br><b style="font-size: 18px;letter-spacing: -1.6px;color:#3162af">${numberFormat(
                        spendingTotal,
                      )}</b><b style="font-size:12px;color:#3162af">원</b>`}
                      series={[
                        {
                          name: ``,
                          colorByPoint: true,
                          innerSize: `65%`,
                          type: `pie`,
                          data: [
                            {
                              name: spendingData[0].data[0].name,
                              y: spendingData[0].data[0].rate,
                            },
                            {
                              name: spendingData[0].data[1].name,
                              y: spendingData[0].data[1].rate,
                            },
                            {
                              name: spendingData[0].data[2].name,
                              y: spendingData[0].data[2].rate,
                            },
                            {
                              name: spendingData[0].data[3].name,
                              y: spendingData[0].data[3].rate,
                            },
                          ],
                        },
                      ]}
                      customOptions={customOptions}
                    />
                  </div>
                  <ChartTable>
                    <table className="tbl1">
                      <colgroup>
                        <col className="col1" />
                        <col className="col2" />
                      </colgroup>
                      <thead>
                        <tr>
                          <th>구분</th>
                          <th>금액(원)</th>
                        </tr>
                      </thead>
                      {spendingData.map((row, index) => (
                        <tbody key={index}>
                          {row.data.map((row2) => (
                            <tr>
                              <td>{row2.name}</td>
                              <td>
                                <p className="price">
                                  {numberFormat(row2.amount)}
                                </p>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <strong>계</strong>
                            </td>
                            <td>
                              <p className="price">
                                {numberFormat(incomeTotalArry[index])}
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                  </ChartTable>
                </ChartContainer>
              </ChartArticle>
              <small>
                후원금 외 수입 · 지출을 포함한 자세한 결산 내역은
                유니세프한국위원회와 국세청 웹사이트에서 확인하실 수 있습니다.
                또한 아래 링크를 통해 자료를 내려 받으실 수 있습니다.
              </small>
              <BtnFlex>
                <li>
                  <Button
                    outline
                    full
                    ico="down"
                    className="btn-report"
                    onClick={() => {
                      window.open(
                        `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2022_audit_report.pdf&svrFileNm=2022_audit_report.pdf`,
                      );
                    }}
                  >
                    <span className="ico">2022 감사보고서</span>
                  </Button>
                </li>
                <li>
                  <Button
                    outline
                    full
                    ico="share"
                    className="btn-report"
                    onClick={() => {
                      window.open(
                        `https://www.hometax.go.kr/websquare/websquare.html?w2xPath=/ui/pp/index.xml`,
                        `_blank`,
                      );
                    }}
                  >
                    <span className="ico">
                      2022 국세청 공익법인 <br />
                      결산서류 공시
                    </span>
                  </Button>
                </li>
                <li>
                  <Button
                    outline
                    full
                    ico="down"
                    className="btn-report"
                    onClick={() => {
                      window.open(
                        `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2022_performance_report.pdf&svrFileNm=2022_performance_report.pdf`,
                      );
                    }}
                  >
                    <span className="ico">
                      2022 국세청 제출 연간 <br />
                      기부모금액 및 활용실적 명세서
                    </span>
                  </Button>
                </li>
              </BtnFlex>
            </article>

            <article>
              <h3>
                <Tit size="s2">
                  효율성, 투명성에 있어 한국가이드스타 공익법인 종합평가 만점 및
                  크라운 인증 동시 획득
                </Tit>
              </h3>
              <p className="dec">
                <ArticleWithImageWrapper>
                  <div className="image">
                    <img
                      src={LogoGuideStar}
                      alt="한국가이드스타 공익법인 종합평가 만점 및 크라운 인증 동시 획득"
                    />
                  </div>
                  <div>
                    유니세프한국위원회는 효율성, 투명성 부문 등의 종합평가에서
                    최고 등급을 받았습니다.
                    <br />
                    <br />
                    국세청 '공익법인 결산서류 등 공시시스템' 결산서류 등을
                    근거로 분석한 재무안정성 및 효율성, 투명성 및 책무성
                    종합평가에서 2020년부터 2년 연속 만점인 별 3개와 크라운
                    인증을 받았습니다. 크라운 인증은 종합평가 만점을 받은 기관
                    중 사업 성과 보고, 국세청 공시서류 및 감사보고서와 이사회
                    회의록 공개, 개인정보처리방침 마련 등 7개 추가 지표 평가를
                    통과한 공익법인에게 수여되는 최고 등급입니다.
                  </div>
                </ArticleWithImageWrapper>
                <br />
                특히 2020년에는 투명성과 책무성(12개), 재무안정성과 효율성(8개),
                20개 세부항목 평가에서도 모두 만점을 받았습니다. 이번 평가는
                국내 9,663개 공익법인 중 9,648개를 대상으로 진행되었으며,
                세부항목 평가에서 모두 만점을 받은 법인은 2020년 4월 현재
                유니세프한국위원회를 포함하여 4개(0.04%)에 불과합니다.
              </p>
            </article>

            <article>
              <h3>
                <Tit size="s2">2022 대한민국 국가사회산업 공헌대상 수상</Tit>
              </h3>
              <p className="dec">
                유니세프한국위원회는 ‘2022 대한민국 국가사회산업 공헌대상’에서
                비영리 기관 ‘세계 어린이 구호’부문을 2년 연속으로 수상했습니다.
                ‘대한민국 국가사회산업 공헌대상’은 (주)헤럴드가 대한민국 국가와
                사회에 공헌하는 사회 주요 분야의 우수 기업을 선정해 시상합니다.
              </p>
            </article>

            <article>
              <h3>
                <Tit size="s2">
                  유니세프, 원조투명성지수(Aid Transparency Index, ATI) 최상위
                  등급 획득
                </Tit>
              </h3>
              <p className="dec">
                유니세프는 투명성과 신뢰도를 높이고자 대중들에게 공개하는 예산,
                결산 자료의 질, 깊이, 시의성을 제고 시켜 왔습니다. 이러한
                노력으로 유니세프는 국제 원조 투명성 캠페인 기관인 Publish What
                You Fund(PWYF)의 2022년 원조투명성지수(Aid Transparency Index,
                ATI) 평가에서 2020년에 이어 2회 연속 최상위 등급(Very Good)을
                받았습니다. 특히 2022년 ATI 평가에서 유니세프는 유엔기구 중 최고
                점수를 받았으며, 더욱이 '재정과 예산' 부문에서는 전체 50개 평가
                대상 중 유일하게 만점을 받았습니다. <br />
                <br />
                ※ PWYF는 개발원조기관의 ① 성과, ② 사업 특성, ③ 재정과 예산, ④
                개발 데이터, ⑤ 기관의 계획과 공약 등 5개 그룹 지표 총 35개
                항목에 걸쳐 격년마다 ATI를 평가해 발표합니다.
                <br />
                평가 결과 보기){' '}
                <a
                  href="https://www.publishwhatyoufund.org/the-index/2022/"
                  target="_new"
                >
                  https://www.publishwhatyoufund.org/the-index/2022/
                </a>
              </p>
            </article>
          </ArcticleContainer>
        </Container>
      </Section>

      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">연도별 사업 재무 보고</Tit>
            </h2>
          </SectionHeader>

          <BtnFlex>
            <li>
              <img src={Report2022} alt="2022 연차보고서" />
              <Button
                outline
                full
                ico="down"
                className="btn-report"
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2022_report.pdf&svrFileNm=2022_report.pdf`,
                  );
                }}
              >
                <span className="ico">2022 연차보고서</span>
              </Button>
            </li>
            <li>
              <img src={Report2021} alt="2021 연차보고서" />
              <Button
                outline
                full
                ico="down"
                className="btn-report"
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2021_report.pdf&svrFileNm=2021_report.pdf`,
                  );
                }}
              >
                <span className="ico">2021 연차보고서</span>
              </Button>
            </li>
            <li>
              <img src={Report2020} alt="2020 연차보고서" />
              <Button
                outline
                full
                ico="down"
                className="btn-report"
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2020_report.pdf&svrFileNm=2020_report.pdf`,
                  );
                }}
              >
                <span className="ico">2020 연차보고서</span>
              </Button>
            </li>
            <li>
              <img src={Report2019} alt="2019 연차보고서" />
              <Button
                outline
                full
                ico="down"
                className="btn-report"
                onClick={() => {
                  window.open(
                    `${process.env.BACKEND_URL}/unicef/api/common/attachFile/download?attUploadPath=L01JRy9jbGVhcg==&dispFileNm=2019_report.pdf&svrFileNm=2019_report.pdf`,
                  );
                }}
              >
                <span className="ico">2019 연차보고서</span>
              </Button>
            </li>
          </BtnFlex>
        </Container>
      </Section>

      <Section>
        <Container>
          <FactBn to="/about-us/notice/82240">
            <Tit size="s3" color="white">
              유니세프한국위원회 <span className="c-yellow">팩트체크</span>
            </Tit>
            <Tit size="s1" color="white">
              유니세프, 믿을 수 있나요?
            </Tit>
            <Image pcSrc={TransparencyBn} mobileSrc={TransparencyBn} />
          </FactBn>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};
export default Transparency;
